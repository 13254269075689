<template>
  <div>
    <el-empty description="正在开发中~"></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>
